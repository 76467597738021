.WWOkVG_button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}
/*# sourceMappingURL=shared.4e6f82f3.css.map */
